import service from "./request";

/**
 * 获取权限列表
 * @param data 
 * @returns 
 */
export function getPermissionList(data: any): Promise<any> {
    return service({
        url: `/permission/list`,
        method: 'POST',
        data
    });
}

/**
 * 删除权限
 * @param data 
 * @returns 
 */
 export function deletePermission(code: string): Promise<any> {
    return service({
        url: `/permission/delete/${code}`,
        method: 'POST',
    });
}

/**
 * 获取单个权限列表
 * @param data 
 * @returns 
 */
export function getPermissionByCode(code: string): Promise<any> {
    return service({
        url: `/permission/detail/${code}`,
        method: 'GET',
    });
}

/**
 * 编辑或修改权限
 * @param data 
 * @returns 
 */
export function editPermission(data: any): Promise<any> {
    return service({
        url: `/permission/edit`,
        method: 'POST',
        data
    });
}


