import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import type { ActionType, ProColumns } from '@ant-design/pro-table';
import { Link, useNavigate } from 'react-router-dom';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import { getWxConfigList, refreshToken } from '@src/api/wx';
import ProTable from '@ant-design/pro-table';
import { useRef } from 'react';
import { formatRequest } from '@src/utils/common';

export type WxConfigItem = {
    id: number;
    name: string;
    appId: string;
    description: string;
    tokenUpdateAt: string,
};


const List = () => {
    const navigate = useNavigate();
    const actionRef = useRef<ActionType>();

    const columns: ProColumns<WxConfigItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '应用名称',
            dataIndex: 'name',
            key: 'name',
            search: false,
            render: (text, record, _, action) => [
                <Link to={`/wx/config/details/${record.id}`}>{text}</Link>
            ],
        },
        {
            title: 'appId',
            dataIndex: 'appId',
            key: 'appId',
            search: false,
        },
        {
            title: 'appSecret',
            dataIndex: 'appSecret',
            key: 'appSecret',
            ellipsis: true,
            search: false,
        },
        {
            title: 'accessToken',
            dataIndex: 'accessToken',
            key: 'accessToken',
            ellipsis: true,
            search: false,
        },
        {
            title: '更新时间',
            dataIndex: 'tokenUpdateAt',
            key: 'tokenUpdateAt',
            search: false,
            render: (_, record) => {
                return <>{record.tokenUpdateAt ? dayjs(record.tokenUpdateAt as string).format('YYYY-MM-DD HH:mm:ss') : '-'}</>
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            search: false,
            width: 100,
            render: (_, record) => {
                return <Button type="link" onClick={() => getToken(record.appId)}>更新</Button>;
            }
        },
    ];

    const addItem = () => {
        navigate('/wx/config/new');
    }

    const getToken = (appId: string) => {
        refreshToken(appId).then(() => {
            actionRef.current?.reload();
            message.success('更新成功')
        })
    }

    return (
        <CContent 
            breadcrumbs={['微信管理', '配置管理']}
        >
            <ProTable<WxConfigItem>
                request={(...e) => formatRequest(getWxConfigList, ...e)}
                columns={columns}
                headerTitle={'配置列表'}
                search={false}
                actionRef={actionRef}
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined />} type="primary" onClick={addItem}>
                        新建
                    </Button>,
                ]}
            />
        </CContent>
    );
};

export default List