import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export interface SelectedMenuState {
    value: string;
}

const initialState: SelectedMenuState = {
    value: '/',
};

export const selectedMenu = createSlice({
    name: 'selectedMenu',
    initialState,
    reducers: {
        setValue: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
            return state;
        },
    },
});

export const { setValue } = selectedMenu.actions;

export const currentSelectedMenu = (state: RootState) => state.selectedMenu.value;



export default selectedMenu.reducer;
