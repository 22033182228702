import RoleList from '@src/pages/system/role/list/index';
import RoleEdit from '@src/pages/system/role/edit';
import RolePermission from '@src/pages/system/role/permission';
import PermissionList from '@src/pages/system/permission/list';
import PermissionEdit from '@src/pages/system/permission/edit';
import UserList from '@src/pages/system/user/list/index';
import UserEdit from '@src/pages/system/user/edit/index';
import ChangePassword from '@src/pages/system/user/changepassword/index';
import MenuList from '@src/pages/system/menu/list/index';
import MenuSort from '@src/pages/system/menu/sort/index';
import MenuEdit from '@src/pages/system/menu/edit/index';
import Dashboard from '@src/pages/dashboard';
import FileList from '@src/pages/system/file/list'
import WxConfigList from '@src/pages/wx/config/list/index'
import WxConfigEdit from '@src/pages/wx/config/edit'
import WxUserList from '@src/pages/wx/user/list/index'
import WeightHistoryList from '@src/pages/weight/history/list/index'
import WeightIdentityList from '@src/pages/weight/identity/list'
import SystemConfigList from '@src/pages/system/config/list/index'
import SystemConfigEdit from '@src/pages/system/config/edit/index'
import WallpaperList from '@src/pages/wallpaper/list/index'

export type PermissionType = {
    code?: 'user' | 'role' | 'menu' | 'permission' | 'file' | null,
    category?: 'view' | 'edit' | 'create' | 'delete' | null
}

export type routerItem = {
    element: JSX.Element,
    title: string,
    path: string,
    parentPath?: string
} & PermissionType

const routerData:routerItem[] = [
    {
        element: <Dashboard />,
        title: '首页',
        path: '/',
    },
    {
        element: <Dashboard />,
        title: '首页',
        path: '/dashboard',
    },
    {
        element: <RoleList />,
        title: '角色列表',
        path: '/system/role/list',
        parentPath: '',
        code: 'role',
        category: 'view',
    },
    {
        element: <RoleEdit />,
        title: '编辑角色',
        path: '/system/role/new',
        parentPath: '/system/role/list',
        code: 'role',
        category: 'create',
    },
    {
        element: <RoleEdit />,
        title: '编辑角色',
        path: '/system/role/details/:id',
        parentPath: '/system/role/list',
        code: 'role',
        category: 'edit',
    },
    {
        element: <RolePermission />,
        title: '角色权限',
        path: '/system/role/permission/:id',
        parentPath: '/system/role/list',
        code: 'role',
        category: 'edit',
    },
    {
        element: <PermissionList />,
        title: '权限列表',
        path: '/system/permission/list',
        parentPath: '',
        code: 'permission',
        category: 'view',
    },
    {
        element: <PermissionEdit />,
        title: '编辑权限',
        path: '/system/permission/new',
        parentPath: '/system/permission/list',
        code: 'permission',
        category: 'create',
    },
    {
        element: <PermissionEdit />,
        title: '编辑权限',
        path: '/system/permission/details/:id',
        parentPath: '/system/permission/list',
        code: 'permission',
        category: 'edit',
    },
    {
        element: <UserList />,
        title: '用户列表',
        path: '/system/user/list',
        parentPath: '',
        code: 'user',
        category: 'view',
    },
    {
        element: <UserEdit />,
        title: '编辑用户',
        path: '/system/user/new',
        parentPath: '/system/user/list',
        code: 'user',
        category: 'create',
    },
    {
        element: <UserEdit />,
        title: '编辑用户',
        path: '/system/user/details/:id',
        parentPath: '/system/user/list',
        code: 'user',
        category: 'edit',
    },
    {
        element: <ChangePassword />,
        title: '修改密码',
        path: '/user/changepassword',
        parentPath: '',
        code: null,
        category: null,
    },
    {
        element: <MenuList />,
        title: '菜单列表',
        path: '/system/menu/list',
        parentPath: '',
        code: 'menu',
        category: 'view',
    },
    {
        element: <MenuSort />,
        title: '菜单排序',
        path: '/system/menu/sort',
        parentPath: '/system/menu/list',
        code: 'menu',
        category: 'edit',
    },
    {
        element: <MenuEdit />,
        title: '编辑菜单',
        path: '/system/menu/details/:id',
        parentPath: '/system/menu/list',
        code: 'menu',
        category: 'edit',
    },
    {
        element: <MenuEdit />,
        title: '编辑菜单',
        path: '/system/menu/new',
        parentPath: '/system/menu/list',
        code: 'menu',
        category: 'create',
    },
    {
        element: <FileList />,
        title: '文件',
        path: '/system/file/list',
        code: 'file',
        category: 'view',
    },
    {
        element: <WxConfigList />,
        title: '微信配置',
        path: '/wx/config/list',
        // code: 'file',
        // category: 'view',
    },
    {
        element: <WxConfigEdit />,
        title: '微信配置',
        path: '/wx/config/new',
        parentPath: '/wx/config/list',
        // code: 'file',
        // category: 'view',
    },
    {
        element: <WxConfigEdit />,
        title: '微信配置',
        path: '/wx/config/details/:id',
        parentPath: '/wx/config/list',
    },
    {
        element: <WxUserList />,
        title: '微信用户',
        path: '/wx/user/list',
    },
    {
        element: <WeightHistoryList />,
        title: '历史体重',
        path: '/weight/history/list',
    },
    {
        element: <WeightIdentityList />,
        title: '历史用户',
        path: '/weight/identity/list',
    },

    

    {
        element: <SystemConfigList />,
        title: '配置文件',
        path: '/system/config/list',
        parentPath: '',
    },
    {
        element: <SystemConfigEdit />,
        title: '配置文件',
        path: '/system/config/new',
        parentPath: '/system/config/list',
    },
    {
        element: <SystemConfigEdit />,
        title: '配置文件',
        path: '/system/config/details/:id',
        parentPath: '/system/config/list',
    },
    {
        element: <WallpaperList />,
        title: '壁纸',
        path: '/wallpaper/list',
        parentPath: '',
    },
]

export default routerData