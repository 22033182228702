import type { ActionType, ProColumns } from '@ant-design/pro-table';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import ProTable from '@ant-design/pro-table';
import { useRef } from 'react';
import { getWeightIdentityList } from '@src/api/weight';
import { formatRequest } from '@src/utils/common';

export type IdentityItem = {
    id: number;
    uid: string,
    name: string,
    target: string,
    mark: string,
    createdAt: string,
    updatedAt: string,
    deletedAt: string,
};


const List = () => {
    const actionRef = useRef<ActionType>();

    const columns: ProColumns<IdentityItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            search: false,
        },
        {
            title: '用户ID',
            dataIndex: 'uid',
            key: 'uid',
            ellipsis: true,
        },
        {
            title: '身份',
            dataIndex: 'name',
            key: 'name',
            search: false,
        },
        {
            title: '目标(kg)',
            dataIndex: 'target',
            key: 'target',
            ellipsis: true,
            search: false,
        },
        {
            title: 'mark',
            dataIndex: 'mark',
            key: 'mark',
            search: false,
            valueEnum: {
                '1': { text: '标记', status: 'Success' },
                '0': { text: '未标记', status: 'Error' },
            },
            
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (_, record) => {
                return <>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '修改时间',
            dataIndex: 'recordAt',
            key: 'recordAt',
            search: false,
            render: (_, record) => {
                return <>{dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '删除时间',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            search: false,
            render: (_, record) => {
                return record.deletedAt ? <>{dayjs(record.deletedAt).format('YYYY-MM-DD HH:mm:ss')}</> : '未删除'
            }
        },
    ];

    return (
        <CContent 
            breadcrumbs={['记录体重', '历史用户']}
        >
            <ProTable<IdentityItem>
                request={(...e) => formatRequest(getWeightIdentityList, ...e)}
                columns={columns}
                headerTitle={'历史用户'}
                actionRef={actionRef}
            />
        </CContent>
    );
};

export default List