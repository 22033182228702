import { Form, Input, Button } from 'antd';
import { useAppDispatch } from '@src/app/hooks';
import { setUser } from '@src/app/reducers/user';
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import './index.less'
import { userLogin } from '@src/api/user';
import { useState } from 'react';
import md5 from 'md5';

const Dashboard = () => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);



    const onFinish = (values: any) => {
        setLoading(true);
        userLogin({ loginName: values.loginName, password: md5(values.password) }).then(res => {
            dispatch(setUser(res));
        }).catch(() => {
            setLoading(false)
        });
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className='login'>
            <div className='login-box'>
                <Form
                    name="basic"
                    labelCol={{ span: 0 }}
                    wrapperCol={{ span: 24 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                
                >
                    <Form.Item
                        name="loginName"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input
                            prefix={
                                <UserOutlined />
                            }
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password
                            prefix={
                                <LockOutlined />
                            }
                        />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Dashboard


