import service from "./request";

/**
 * 获取文件
 * @returns 
 */
 export function getFileList(data: any) {
    return service({
        url: `/file/list`,
        method: 'POST',
        data
    });
}


/**
 * 删除文件
 * @returns 
 */
export function deleteFile(code: string) {
    return service({
        url: `/file/delete/${code}`,
        method: 'POST',
    });
}

/**
 * 获取全部文件类型
 * @returns 
 */
export function getMimetype(): Promise<any> {
    return service({
        url: `/file/mimetype`,
        method: 'GET',
    });
}
