import { store } from '@src/app/store';
import { message } from 'antd';
import axios from 'axios';
import { clearUser } from '@src/app/reducers/user';


export const BaseURL = process.env.NODE_ENV === 'production' ? 'https://z.heyali.cn' : 'http://localhost:10001';



const service = axios.create({
    baseURL: BaseURL,
    timeout: 10000,
});

service.interceptors.request.use(
    config => {
        config.headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': store.getState().user.token
        }

        return config;
    },
    error => {
        console.log('==>' ,error);
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        if(response.data && response.data.code === 200) {
            const res = response.data?.data || null;
            return Promise.resolve(res);
        } else {
            if (response.data && response.data.code === 403) {
                store.dispatch(clearUser());
            }
            message.error(response.data.message);
            return Promise.reject(response.data.msg || response.data.message);
        }
    },
    error => {
        let msg = '';
        if(error.response && error.response.data && error.response.data.message) {
            msg = error.response.data.message || error.response.data.msg;
        } else if (error.message) {
            msg = error.message;
        } else {
            msg = JSON.stringify(error);
        }

        message.error(msg);
        return Promise.reject(msg);
    }
);

export default service;