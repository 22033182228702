import type { ActionType, ProColumns } from '@ant-design/pro-table';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import ProTable from '@ant-design/pro-table';
import { useRef } from 'react';
import { getWeightHistoryList } from '@src/api/weight';
import { formatRequest } from '@src/utils/common';

export type HistoryItem = {
    id: number;
    createdAt: string,
    description: string,
    height: string,
    bmi: string,
    weight: string,
    uid: string,
    identityId: string,
    recordAt: string,
    updatedAt: string,
    deletedAt: string,
};


const List = () => {
    const actionRef = useRef<ActionType>();

    const columns: ProColumns<HistoryItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            ellipsis: true,
            search: false,
        },
        {
            title: '用户ID',
            dataIndex: 'uid',
            key: 'uid',
            ellipsis: true,
        },
        {
            title: '身份ID',
            dataIndex: 'identityId',
            key: 'identityId',
            ellipsis: true,
        },
        {
            title: '体重(kg)',
            dataIndex: 'weight',
            key: 'weight',
            ellipsis: true,
            search: false,
        },
        {
            title: '身高(cm)',
            dataIndex: 'height',
            key: 'height',
            ellipsis: true,
            search: false,
        },
        {
            title: 'BMI',
            dataIndex: 'bmi',
            key: 'bmi',
            ellipsis: true,
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            search: false,
        },
        {
            title: '填写时间',
            dataIndex: 'recordAt',
            key: 'recordAt',
            search: false,
            render: (_, record) => {
                return <>{dayjs(record.recordAt).format('YYYY-MM-DD')}</>
            }
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (_, record) => {
                return <>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '修改时间',
            dataIndex: 'recordAt',
            key: 'recordAt',
            search: false,
            render: (_, record) => {
                return <>{dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '删除时间',
            dataIndex: 'deletedAt',
            key: 'deletedAt',
            search: false,
            render: (_, record) => {
                return record.deletedAt ? <>{dayjs(record.deletedAt).format('YYYY-MM-DD HH:mm:ss')}</> : '未删除'
            }
        },
    ];


    return (
        <CContent 
            breadcrumbs={['记录体重', '历史体重']}
        >
            <ProTable<HistoryItem>
                request={(...e) => formatRequest(getWeightHistoryList, ...e)}
                columns={columns}
                headerTitle={'历史体重'}
                // search={false}
                actionRef={actionRef}
            />
        </CContent>
    );
};

export default List