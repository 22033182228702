import { Button, DatePicker, Image, Modal, Select } from 'antd';
import type { ActionType, ProColumns } from '@ant-design/pro-table';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import { getWxConfigList, getWxUserList } from '@src/api/wx';
import ProTable from '@ant-design/pro-table';
import { useEffect, useRef, useState } from 'react';
import { WxConfigItem } from '../../config/list';
import { formatRequest } from '@src/utils/common';
import moment from 'moment';
import ReactJson from 'react-json-view';

const { Option } = Select;

const { RangePicker } = DatePicker;


export type WxUserItem = {
    id: number;
    avatarUrl: string,
    nickName: string,
    openId: string,
    unionId: string,
    loginIp: string,
    app: {
        name: string
    },
    headers: string,
};


const List = () => {
    const actionRef = useRef<ActionType>();
    const [wxConfigList, setWxConfigList] = useState<WxConfigItem[]>([])
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [headers, setHeaders] = useState({});

    useEffect(() => {
        getWxConfigList({pageSize: 30, pageNum: 1}).then(res => {
            setWxConfigList(res.data)
        })
    }, [])

    const columns: ProColumns<WxUserItem>[] = [
        {
            title: '项目筛选',
            dataIndex: 'appId',
            key: 'appId',
            hideInTable: true,
            renderFormItem: (_, config) => {
                return (
                    <Select {...config} placeholder="请选择" allowClear>
                        {
                            wxConfigList.map(item => <Option key={item.appId} value={item.appId}>{item.name}</Option>)
                        }
                    </Select>
                )
            }
        },
        {
            title: '是否注册',
            dataIndex: 'isRegister',
            key: 'isRegister',
            hideInTable: true,
            renderFormItem: (_, config) => {
                return (
                    <Select {...config} placeholder="请选择" allowClear>
                        <Option value={true}>是</Option>
                        <Option value={false}>否</Option>
                    </Select>
                )
            }
        },
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '小程序',
            dataIndex: 'app',
            key: 'app',
            search: false,
            render: (_, record) => [
                record.app.name
            ],
        },
        {
            title: 'id',
            dataIndex: 'id',
            key: 'id',
            width: 40,
            ellipsis: true,
        },
        {
            title: 'avatarUrl',
            dataIndex: 'avatarUrl',
            key: 'avatarUrl',
            search: false,
            render: (_, record) => [
                record.avatarUrl ? <Image src={record.avatarUrl} preview width={40} /> : '-'
            ],
        },
        {
            title: 'nickName',
            dataIndex: 'nickName',
            key: 'nickName',
            search: false,
        },
        {
            title: 'openId',
            dataIndex: 'openId',
            key: 'openId',
            ellipsis: true,
        },
        {
            title: 'unionId',
            dataIndex: 'unionId',
            key: 'unionId',
            ellipsis: true,
        },
        {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
            ellipsis: true,
            search: false,
            valueEnum: {
                '0': { text: '未知', status: 'Success' },
                '1': { text: '男性', status: 'Success' },
                '2': { text: '女性', status: 'Success' },
            },
            
        },
        {
            title: '登陆时间',
            dataIndex: 'loginAt',
            key: 'loginAt',
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            },
            renderFormItem: () => {
                return (
                    <RangePicker
                        placeholder={['开始时间', '结束时间']}
                        ranges={{
                            '今天': [moment(), moment()],
                            '昨天': [moment().add(-1, 'day'), moment().add(-1, 'day')],
                            '本月': [moment().startOf('month'), moment().endOf('month')],
                        }}
                    />
                )
            }
        },
        {
            title: '登陆IP',
            dataIndex: 'loginIp',
            key: 'loginIp',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: 'Headers',
            dataIndex: 'action',
            search: false,
            width: 100,
            render: (_, record) => {
                return <Button type="link" onClick={() => {setIsModalVisible(true); setHeaders(JSON.parse(record.headers))}}>查看</Button>;
            }
        },
    ];

    return (
        <CContent
            breadcrumbs={['微信管理', '配置管理']}
        >
            <>
                <ProTable<WxUserItem>
                    request={(...e) => formatRequest(getWxUserList, ...e)}
                    columns={columns}
                    headerTitle={'配置列表'}
                    actionRef={actionRef}
                />
                <Modal title="Request Headers"
                    width={700}
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={false}
                >
                    <ReactJson
                        name={false}
                        enableClipboard={false}
                        displayDataTypes={false}
                        src={headers}
                    />
                </Modal>
            </>
        </CContent>
    );
};

export default List