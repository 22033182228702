import { Button, DatePicker, Image, message, Modal } from 'antd';
import type { ActionType, ProColumns } from '@ant-design/pro-table';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import ProTable from '@ant-design/pro-table';
import { useRef, useState } from 'react';
import { formatRequest } from '@src/utils/common';
import { getWallpaperList, refreshWallpaper } from '@src/api/wallpaper';
import { SyncOutlined } from '@ant-design/icons';
import ReactJson from 'react-json-view';


export type WxUserItem = {
    id: number;
    date: string,
    info: string,
    createdAt: string,
    localPath: string,
    url: string,
};


const List = () => {
    const actionRef = useRef<ActionType>();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [info, setInfo] = useState({});


    const columns: ProColumns<WxUserItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            valueType: 'indexBorder',
            width: 48,
        },

        {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            search: false,
            render: (_, record) => [
                record.url ? <Image src={record.url} preview width={80} /> : '-'
            ],
        },
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            renderFormItem: (_, config) => {
                return (
                    <DatePicker />
                )
            }
        },
        {
            title: '本地地址',
            dataIndex: 'localPath',
            key: 'localPath',
            ellipsis: true,
            search: false,
        },
        {
            title: '信息',
            dataIndex: 'info',
            key: 'info',
            ellipsis: true,
            search: false,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '详情',
            dataIndex: 'action',
            search: false,
            width: 100,
            render: (_, record) => {
                return <Button type="link" onClick={() => {setIsModalVisible(true); setInfo(JSON.parse(record.info))}}>查看</Button>;
            }
        },
    ];

    const refreshData = () => {
        const key = 'refreshData';
        message.loading({ content: 'Loading...', key});
        refreshWallpaper({}).then(() => {
            actionRef.current?.reload()
            message.success({ content: '获取壁纸成功', key, duration: 2 });
        }).catch(() => {
            message.destroy(key)
        })
    }

    return (
        <CContent 
            breadcrumbs={['必应壁纸', '历史壁纸']}
        >
            <>
                <ProTable<WxUserItem>
                    request={(...e) => formatRequest(getWallpaperList, ...e)}
                    columns={columns}
                    headerTitle={'历史壁纸'}
                    actionRef={actionRef}
                    toolBarRender={() => [
                        <Button key="button" icon={<SyncOutlined />} type="primary" onClick={refreshData}>
                            获取最近几天壁纸
                        </Button>,
                    ]}
                />
                <Modal title="壁纸详情"
                    width={700}
                    visible={isModalVisible}
                    onCancel={() => setIsModalVisible(false)}
                    footer={false}
                >
                    <ReactJson
                        name={false}
                        enableClipboard={false}
                        displayDataTypes={false}
                        src={info}
                    />
                </Modal>
            </>
        </CContent>
    );
};

export default List