import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Space, Upload } from 'antd';
import type { ActionType, ProColumns } from '@ant-design/pro-table';
import BaseTable from '@src/components/BaseTable.tsx'
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import { useAppSelector } from '@src/app/hooks';
import { getUserState } from '@src/app/reducers/user';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { deleteFile, getFileList, getMimetype } from '@src/api/file';
import { BaseURL } from '@src/api/request';
import { sizeTostr } from '@src/utils/common';
import { useEffect, useRef, useState } from 'react';
import './index.less'

type FileItem = {
    id: number,
    code: string,
    fieldname: string,
    originalname: string,
    encoding: string,
    mimetype: string,
    destination: string,
    filename: string,
    path: string,
    size: number,
    createdUser: {
        name: string,
    }
}

const List = () => {
    const tableRef = useRef<ActionType>();
    const [loading, setLoading] = useState(false);
    const [mimetypeEnum, setMimetypeEnum] = useState({});
    const { token } = useAppSelector(getUserState);

    useEffect(() => {
        getMimetype().then((res: string[]) => {
            let obj: any = {};
            res.forEach(i => {
                obj[i] = { text: i }
            })
            setMimetypeEnum(obj)
            console.log(res);
        })
    },[])

    const uploadProps = {
        name: 'file',
        action:  BaseURL + '/file/upload',
        headers: {
            authorization: token
        },
        showUploadList: false,
        onChange(info: UploadChangeParam<UploadFile>) {
            if(info.file.status === 'uploading') {
                setLoading(true)
            }
            
            if(info.file.status === 'done') {
                tableRef.current?.reload(true);
                if(info.file.response.code === 200) {
                    message.success('上传成功');
                }
                else
                    message.error(info.file.response.message);
                setLoading(false)
            }
        }
    };

    const deleteFileItem = (file: FileItem) => {
        Modal.confirm({
            title: `确认要删除【${file.originalname}】吗？`,
            okText: '确认',
            cancelText: '取消',
            maskClosable: true,
            onOk: () => {
                deleteFile(file.code).then(_ => {
                    message.success('删除成功');
                    tableRef.current?.reload(true);
                })
            },
            onCancel: () => {}
        })
    }

    const columns: ProColumns<FileItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '文件名',
            dataIndex: 'originalname',
            key: 'originalname',
            ellipsis: true
        },
        {
            title: '文件类型',
            dataIndex: 'mimetype',
            key: 'mimetype',
            valueType: 'select',
            valueEnum: mimetypeEnum
        },
        {
            title: '大小',
            dataIndex: 'size',
            key: 'size',
            search: false,
            render: (_, record) => (
                <>{sizeTostr(record.size)}</>
            )
        ,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
        {
            title: '创建人',
            dataIndex: 'permission',
            key: 'permission',
            search: false,
            render: (_, record) => (
                    <>{record.createdUser.name}</>
                )
            ,
        },
        {
            title: '操作',
            dataIndex: 'action',
            search: false,
            width: 100,
            render: (_, record) => {
                return <Space>
                    <Button className='but_link' type="link" onClick={() => window.open(`${BaseURL}/file/assets/${record.code}`)}>预览</Button>
                    <Button className='but_link' type="link" onClick={() => deleteFileItem(record)}>删除</Button>
                </Space>
            }
        },
    ];

    return (
        <CContent 
            breadcrumbs={['系统设置', '文件列表']}
        >
            <BaseTable
                ref={tableRef}
                request={getFileList}
                columns={columns}
                headerTitle={'文件列表'}
                toolBarRender={() => [
                    <Upload
                        {...uploadProps}
                    >
                        <Button key="button" icon={<UploadOutlined />} type="primary" loading={loading}>
                            上传
                        </Button>
                    </Upload>,
                ]}
            />
        </CContent>
    );
};

export default List
