import service from "./request";

export function getSystemConfigList(data: any): Promise<any> {
    return service({
        url: `/system/config/list`,
        method: 'POST',
        data
    });
}

export function editSystemConfig(data: any): Promise<any> {
    return service({
        url: `/system/config/edit`,
        method: 'POST',
        data
    });
}

export function getSystemConfig(id: number) {
    return service({
        url: `/system/config/details/${id}`,
        method: 'GET',
    });
}

export function deleteSystemConfig(id: number) {
    return service({
        url: `/system/config/delete/${id}`,
        method: 'POST',
    });
}