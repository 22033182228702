import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MenuItem, Permission } from '@src/type';
import { RootState, AppThunk } from '../store';
import Cookies from 'js-cookie';

export type UserInfo = {
    loginName: string;
    name: string;
    avatar: string;
    mail: string;
}



export interface UserState {
    info: UserInfo,
    token: string,
    permission: Permission,
    menus: MenuItem[]
}

const initialState = (): UserState => {
    try {
        const result = JSON.parse(localStorage.getItem('___u') as string);
        if(!result) throw Error('error data');
        return result;
    } catch (error) {
        localStorage.removeItem('___u');
         return {
            info: {
                loginName: '',
                name: '',
                avatar: '',
                mail: '',
            },
            token: '',
            permission: {},
            menus: []
        };
    }
}


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
    'counter/fetchCount',
    async (amount: number) => {
        // const response = await fetchCount(amount);
        // The value we return becomes the `fulfilled` action payload
        return "";
    }
);

export const userinfoSlice = createSlice({
    name: 'user',
    initialState: initialState(),
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setUser(state, action: PayloadAction<UserState>) {
            // const menus = listToTree(action.payload.menus);
            // state = { ...action.payload, menus };

            state = {...state, ...action.payload};

            localStorage.setItem('___u', JSON.stringify(state));
            Cookies.set('HA_AUTHORIZATION', action.payload.token, { expires: 3 })
            return state;
        },
        clearUser(state) {
            localStorage.removeItem('___u');
            Cookies.remove('HA_AUTHORIZATION');

            state = initialState()
            return state;
        },
        increment: (state, action: PayloadAction<UserInfo>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            // state.value += 1;

            state.info.loginName = action.payload.loginName
            // state = { ...state, ...action.payload }
            console.warn(state);
            
        },
        decrement: (state) => {
            // state.value -= 1;
        },
        // Use the PayloadAction type to declare the contents of `action.payload`
        incrementByAmount: (state, action: PayloadAction<UserInfo>) => {
            // state = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
        .addCase(incrementAsync.pending, (state) => {
            // state.status = 'loading';
        })
        .addCase(incrementAsync.fulfilled, (state, action) => {
            // state.status = 'idle';
            // state.value += action.payload;
        });
    },
});

export const { setUser, clearUser, increment, decrement, incrementByAmount } = userinfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state: RootState) => state.user.info;

export const getUserState = (state: RootState) => state.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const incrementIfOdd = (amount: UserInfo): AppThunk => (
    dispatch,
    getState
) => {
    // const currentValue = selectCount(getState());
    // if (currentValue % 2 === 1) {
        dispatch(incrementByAmount(amount));
    // }
};

export default userinfoSlice.reducer;
