import { getRolePermissionList, saveRolePermission } from '@src/api/role';
import { RoutedProps, withRouter } from '@src/components/withRouter';
import CContent from '@src/layout/content';
import { Checkbox, Row, Col, Space, Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import './index.less'

type Props = {
} & RoutedProps

type ModelType = {
    current: number[],
    list: {
        name: string,
        code: string,
        children: {
            id: number,
            code: string,
            category: string,
        }[]
    }[]
}

const category: {[x: string]: string} = {
    view: '查看',
    edit: '编辑',
    create: '创建',
    delete: '删除'
}

const RoleEdit: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState<ModelType>({current: [], list:[]});


    useEffect(() => {
        getRolePermissionList(props.params.id).then((res: ModelType) => {
            setModel(res);
        })
    }, [props.params])


    const onFinish = (values: any) => {
        setLoading(true)
        saveRolePermission({role_id: props.params.id, permissions: model.current}).then(() => {
            Modal.success({ 
                title: '编辑成功',
                onOk: () => {
                    props.navigate(-1)
                }
            });
        }).finally(() => {
            setLoading(false)
        });
    };

    const onChange = (checkedValue: any[]) => {
        setModel({
            ...model,
            current: checkedValue
        })
    }

    const handleSelectAll = () => {
        const { list, current } = model;
        const _current = list.map(i => i.children.map(i => i.id)).reduce((previousValue, currentValue) => [...previousValue, ...currentValue], []);
        if(current.length === _current.length)
            setModel({current: [], list})
        else
            setModel({current: _current, list})
    }


    return (
        <CContent 
            breadcrumbs={['系统设置', '角色列表', '分配权限']}
        >
            <div>
                {
                    model.list.length > 0
                    ?
                    <Checkbox.Group style={{ width: '100%' }} value={model.current} onChange={onChange}>
                        {
                            model.list.map((item, index) => (
                                <Row key={index} gutter={[32, 32]} className="row">
                                    <Col span={4}>
                                        <span>{item.name}</span>
                                    </Col>
                                    {
                                        item.children.map((i, idx) => (
                                            <Col span={4} key={idx}>
                                                <Checkbox value={i.id}>{category[i.category]}</Checkbox>
                                            </Col>
                                        ))
                                    }
                                </Row>
                            ))
                        }
                    </Checkbox.Group>
                    :
                    <></>
                }
                <Space>
                    <Button type="primary" htmlType="submit" loading={loading} onClick={onFinish}>
                        提交
                    </Button>
                    <Button onClick={handleSelectAll}>全选</Button>
                </Space>
            </div>
        </CContent>

    )
}

export default withRouter(RoleEdit)