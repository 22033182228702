import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { SortOrder } from 'antd/lib/table/interface';
import { ToolBarProps } from '@ant-design/pro-table/lib/components/ToolBar';


type Props = {
    columns: ProColumns[],
    request: (_: any) => Promise<any>,
    headerTitle?: string,
    toolBarRender?: ToolBarProps<any>['toolBarRender'] | false;
}


const BaseTable = (prop: Props, ref: any) => {
    const actionRef = useRef<ActionType>();

    useImperativeHandle(ref, () => ({
        ...actionRef.current
    }), []);

    const request = (params: any = {}, sort: Record<string, SortOrder>, filter: Record<string, React.ReactText[] | null>) => {
        const _params = {...params};
        _params.pageNum = params.current;
        delete _params.current;

        return prop.request(_params).then((res: any) => ({...res, success: true}));
    }
    

    return (
        <ProTable
            columns={prop.columns}
            actionRef={actionRef}
            request={ request }
            rowKey="id"
            pagination={{
                pageSize: 10,
            }}
            dateFormatter="string"
            headerTitle={prop.headerTitle}
            toolBarRender={prop.toolBarRender}
        />
    );
};

export default forwardRef(BaseTable)