import { Menu } from "antd";
import * as Icon from '@ant-design/icons'
import { useAppSelector } from "@src/app/hooks";
import { getUserState } from "@src/app/reducers/user";
import { currentSelectedMenu } from "@src/app/reducers/menu";
import { MenuItem, Permission } from "@src/type";

import '../index.less'
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";


const { SubMenu } = Menu;

const CIcon = ({name}: {name: string}) => {
    return React.createElement(Icon && (Icon as any)[name], {
        style: { fontSize: '16px' }
    })
}

const CSubMenu = (menus: MenuItem[], permission: Permission) => {
    return menus.map(item => (
        item.permission && !permission[item.permission.code]?.[item.permission.category] ?
        <></>
        :
        (item.childrens.length > 0
        ?
        <SubMenu
            key={item.id}
            title={item.name}
            icon={item.icon ? <CIcon name={item.icon} /> : <></>}
        >
            {CSubMenu(item.childrens, permission)}
        </SubMenu>
        :
        <Menu.Item
            key={item.id}
            icon={item.icon ? <CIcon name={item.icon} /> : <></>}
        >
            {item.name}
        </Menu.Item>)
    ))
}


const CMenu = ({collapsed}: {collapsed: boolean}) => {
    const navigate = useNavigate();
    const { menus, permission } = useAppSelector(getUserState);
    const selectedMenu = useAppSelector(currentSelectedMenu);

    const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<string[]>([]);
    const [defaultOpenKeys, setDefaultOpenKeys] = useState<string[]>([]);

    useEffect(() => {
        const getDefault = (_menus: MenuItem[], path: string, openKeys: string[] = [], callback?: (openKeys: string[], item: MenuItem) => void) => {
            for (const item of _menus) {
                if(path === item.url && callback) {
                    callback(openKeys, item);
                    return;
                }
                else getDefault(item.childrens, path, [...openKeys, item.id.toString()], callback);
            }
        }

        getDefault(menus, selectedMenu, [], (openKeys, item) => {
            setDefaultOpenKeys(openKeys);
            setDefaultSelectedKeys([item.id.toString()]);
        })
    }, [menus, selectedMenu]);


    const handOnClick = ({ item, key, keyPath, domEvent }: any) => {
        getMenuIten(menus, parseInt(key), item => {
            navigate(item.url)
        });
    }

    const handOnOpenChange = (openKeys: string[]) => {
        setDefaultOpenKeys(openKeys);
    }

    const getMenuIten = (_menus: MenuItem[], key: number, callback?: (item: MenuItem) => void): any =>  {
        for (const item of _menus) {
            if(item.id === key && callback) callback(item);
            else getMenuIten(item.childrens, key, callback);
        }
    }

    return (
        <Menu
            mode="inline"
            inlineCollapsed={collapsed}
            selectedKeys={defaultSelectedKeys}
            openKeys={defaultOpenKeys}
            onClick={handOnClick}
            onOpenChange={handOnOpenChange}
        >
            {CSubMenu(menus, permission)}
        </Menu>
    )
}

export default CMenu