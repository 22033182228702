import service from "./request";


 export function getWeightHistoryList(data: any) {
    return service({
        url: `/weight/history/list`,
        method: 'POST',
        data
    });
}

 export function getWeightIdentityList(data: any) {
    return service({
        url: `/weight/identity/list`,
        method: 'POST',
        data
    });
}