import { deleteSystemConfig, editSystemConfig, getSystemConfig } from '@src/api/system';
import Gurad from '@src/components/Gurad';
import { RoutedProps, withRouter } from '@src/components/withRouter';
import CContent from '@src/layout/content';
import { MenuItem } from '@src/type';
import { Form, Input, Button, Space, Modal, Col, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect, useRef, useState } from 'react';


type Props = {
} & RoutedProps


const RoleEdit: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState<MenuItem>({} as any);
    const formRef = useRef<any>(null);


    useEffect(() => {
        if(props.params.id) {
            getSystemConfig(props.params.id).then((res: any) => {
                const data = {...res, value: JSON.parse(res.value)}
                setModel(data);
                formRef.current.setFieldsValue(data);
            });
        }
    }, [props.params])


    const onFinish = (values: any) => {
        setLoading(true);

        editSystemConfig({...values, value: (values.value || []).map((i: any) => ({key: i.key, value: i.value})), id: props.params.id}).then(() => {
            Modal.success({ 
                title: '编辑成功',
                onOk: () => {
                    props.navigate(-1)
                }
            });
        }).finally(() => {
            setLoading(false)
        });
    };

    const onDelete = () =>  {
        Modal.confirm({
            title: '确认要删除此项吗？',
            okText: '确认',
            cancelText: '取消',
            maskClosable: true,
            onOk: () => {
                deleteSystemConfig(model.id).then(_ => {
                    Modal.success({ 
                        title: '删除成功',
                        onOk: () => {
                            props.navigate(-1)
                        }
                    });
                })
            },
            onCancel: () => {}
        })
    }


    return (
        <CContent 
            breadcrumbs={['系统设置', '配置编辑']}
            style={{paddingTop: '24px'}}
        >
            <Form
                ref={formRef}
                name="basic"
                labelCol={{ span: 3, xs: 5, sm: 3}}
                wrapperCol={{ span: 8 }}
                initialValues={{...model}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="name"
                    name="name"
                    rules={[{ required: true, message: '请输入名称' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="key"
                    name="key"
                    rules={[{ required: true, message: '请输入key' }]}
                >
                    <Input />
                </Form.Item>

                <Row style={{marginBottom: '16px'}}>
                    <Col span={3}></Col>
                    <Col><Button>---Value---</Button></Col>
                </Row>
                <Form.List
                    name="value"
                >
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {
                                fields.map((field, index) => (
                                    <Row key={index}>
                                        <Col span={6}>
                                            <Form.Item
                                                label='key'
                                                name={[field.name, 'key']}
                                                rules={[{ required: true, message: 'key' }]}
                                                labelCol={{span: 12}}
                                                wrapperCol={{span: 12}}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label='value'
                                                name={[field.name, 'value']}
                                                rules={[{ required: true, message: 'value' }]}
                                                labelCol={{span: 8}}
                                                wrapperCol={{span: 12}}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Button onClick={() => remove(index)}>remove</Button>
                                        </Col>
                                    </Row>
                                ))
                            }
                            <Row style={{marginBottom: '16px'}}>
                                <Col span={3}></Col>
                                <Col><Button onClick={add}>add</Button></Col>
                            </Row>
                        </>
                    )}
                </Form.List>



                <Form.Item
                    label="description"
                    name="description"
                    rules={[{ required: false, message: '请输入名称' }]}
                >
                    <TextArea  autoSize />
                </Form.Item>


                <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                    <Space>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            提交
                        </Button>
                        {
                            model.id ?
                            <Gurad code='menu' category='delete'>
                                <Button type="primary" danger loading={loading} onClick={onDelete}>
                                    删除
                                </Button>
                            </Gurad>
                            :
                            <></>
                        }
                    </Space>
                </Form.Item>
            </Form>
        </CContent>

    )
}

export default withRouter(RoleEdit)