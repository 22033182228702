import { Breadcrumb, Layout } from "antd";
import '../index.less'

type Props = {
    children: JSX.Element,
    breadcrumbs: string[],
    style?: React.CSSProperties
}

const CContent = ({ children, breadcrumbs, style }: Props) => {
    const { Content } = Layout;

    return (
        <div className="site-page-content">
            <div>
                <Breadcrumb style={{ margin: '16px 0' }}>
                    {
                        breadcrumbs.map((item, index) =>(
                            <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                        ))
                    }
                </Breadcrumb>
            </div>
            <Content
                className=""
                style={{
                    padding: 0,
                    margin: 0,
                    ...style
                }}
            >
                {children}
            </Content>
        </div>
    )
}

export default CContent