import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ProColumns } from '@ant-design/pro-table';
import { getPermissionList } from '@src/api/permission';
import BaseTable from '@src/components/BaseTable.tsx'
import { Link, useNavigate } from 'react-router-dom';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';

type ColumnsItem = {
    id: number;
    name: string;
    code: string;
    description: string;
};


const List = () => {
    const navigate = useNavigate();

    const columns: ProColumns<ColumnsItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '权限名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record, action) => [
                <Link to={`/system/permission/details/${record.code}`}>{text}</Link>
            ],
        },
        {
            title: '权限code',
            dataIndex: 'code',
            key: 'code',
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
            search: false,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
    ];

    const addItem = () => {
        navigate('/system/permission/new');
    }

    return (
        <CContent 
            breadcrumbs={['系统设置', '权限列表']}
        >
            <BaseTable
                request={getPermissionList}
                columns={columns}
                headerTitle={'角色列表'}
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined />} type="primary" onClick={addItem}>
                        新建
                    </Button>,
                ]}
            />
        </CContent>
    );
};

export default List