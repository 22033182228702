import React from 'react';
import './App.css';
import Router from './router/index'

// import 'antd/dist/antd.css'

function App() {
    return (
        <div className="App">
            <Router />
        </div>
    );
}

export default App;
