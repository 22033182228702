import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import Login from '../pages/login/login';
import Layout from '../layout/index';
import ErrorPage from '../layout/errorpage';
import { useAppDispatch, useAppSelector } from '@src/app/hooks';
import { getUserState } from '@src/app/reducers/user';
import routerData, { PermissionType } from './routers';
import { setValue } from '@src/app/reducers/menu';


const PrivateRoute = (props: { children: React.ReactNode, to?: string }): JSX.Element => {
    const user = useAppSelector(getUserState);
    const {children, to} = props;
    const isLogin = user.token;

    return isLogin ?
    (
        to === '/login'
        ?
        <Navigate replace={true} to="/" state={{ from: `${window.location.pathname}${window.location.search}` }} />
        :
       <>{children}</>
    ) : (
        to === '/login'
        ?
        <>{children}</>
        :
        <Navigate replace={true} to="/login" state={{ from: `${window.location.pathname}${window.location.search}` }} />
    )
}

type AuthenticatedProps = {
    children: React.ReactNode,
    selectedMenu: string,
} & PermissionType

const Authenticated = ({ children, code, category, selectedMenu }: AuthenticatedProps): JSX.Element => {
    const dispatch = useAppDispatch();
    dispatch(setValue(selectedMenu));
    const userPermission = useAppSelector(getUserState).permission;

    if(code && category && !userPermission[code]?.[category])
        return <ErrorPage type='403' />
    else
        return <>{children}</>;
}


const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoute to='/'><Layout /></PrivateRoute>}>
                    {
                        routerData.map((item, index) => (
                            <Route
                                key={index}
                                path={item.path}
                                element={
                                    <Authenticated
                                        code={item.code}
                                        category={item.category}
                                        selectedMenu={item.parentPath || item.path}
                                    >
                                        {item.element}
                                    </Authenticated>
                                }
                            />
                        ))
                    }
                    <Route path="/*" element={<ErrorPage type='404' />} />
                </Route>
                <Route path="/login" element={<PrivateRoute to="/login"><Login/></PrivateRoute>} />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;