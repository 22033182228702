import { RoutedProps, withRouter } from '@src/components/withRouter';
import CContent from '@src/layout/content';
import {  Button, message } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { getAllMenu, setMenuSort } from '@src/api/menu';
import * as Icon from '@ant-design/icons'
import Sortable from 'sortablejs';

import './index.less'
import { MenuItem } from '@src/type';



type Props = {
} & RoutedProps



const CIcon = ({name}: {name: string}) => {
    return React.createElement(Icon && (Icon as any)[name], {
        style: { fontSize: '16px' }
    })
}

const SortableItem = (({value}: {value: MenuItem}) => {
    return (
        <li className='tree-item' data-id={value.id}>
            <h3>
                {value.icon ? <CIcon name={value.icon} /> : <></>}
                <span>
                    {value.name}
                </span>
            </h3>
            {
                value.childrens.length > 0
                ?
                <SortableList menus={value.childrens} />
                :
                <ul className='tree'></ul>
            }
        </li>
    )
});

const SortableList = (({menus}: { menus: MenuItem[]}) => {
    return (
        <ul className='tree'>
            {menus.map((value, index) => (
                <SortableItem key={`item-${value.id}`} value={value}  />
            ))}
        </ul>
    );
});


const MenuIndex: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [menus, setMenus] = useState<MenuItem[]>([]);
    const treeRef = useRef<HTMLDivElement>(null)


    useEffect(() => {
        getAllMenu().then((res: any) => {
            setMenus(res);

            var nestedSortables = [].slice.call(document.querySelectorAll('ul.tree'));

            for (var i = 0; i < nestedSortables.length; i++) {
                new Sortable(nestedSortables[i], {
                    group: 'nested',
                    animation: 150
                });
            }
        });
    }, [])




    const onFinish = () => {
        setLoading(true);

        const ids = [...(treeRef.current?.querySelectorAll('li.tree-item') as unknown as Array<HTMLHtmlElement>)].map(item => {
            return {
                id: parseInt(item.getAttribute('data-id') as string),
                pid: parseInt(item.parentElement?.parentElement?.getAttribute('data-id') as string)|| null,
            }
        });
        setMenuSort({ids}).then(() => {
            message.success('保存成功');
        }).finally(() => {
            setLoading(false)
        });
    };

    return (
        <CContent 
            breadcrumbs={['系统设置', '菜单设置']}
        >
            <div>
                <Button
                    type="primary"
                    icon={<SaveOutlined />}
                    loading={loading}
                    onClick={onFinish}
                >
                    保存排序
                </Button>
                <div className='sort-content' ref={treeRef}>
                    <SortableList menus={menus} />
                </div>
            </div>
        </CContent>

    )
}

export default withRouter(MenuIndex)

