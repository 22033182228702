import service from "./request";

/**
 * 获取配置
 * @returns 
 */
 export function getWxConfigList(data: any) {
    return service({
        url: `/wx/config/list`,
        method: 'POST',
        data
    });
}


/**
 * 获取配置
 * @returns 
 */
 export function editWxConfig(data: any) {
    return service({
        url: `/wx/config/edit`,
        method: 'POST',
        data
    });
}

/**
 * 配置查询
 * @param data 
 * @returns 
 */
 export function getWxConfigDetails(id: any): Promise<any> {
    return service({
        url: `/wx/config/details/${id}`,
        method: 'GET',
    });
}

/**
 * 配置删除
 * @param data 
 * @returns 
 */
export function deleteWxconfig(id: any): Promise<any> {
    return service({
        url: `/wx/config/delete/${id}`,
        method: 'POST',
    });
}


/**
 * 更新token
 * @param appId 
 * @returns 
 */
 export function refreshToken(appId: any): Promise<any> {
    return service({
        url: `/wx/config/refreshtoken/${appId}`,
        method: 'POST',
    });
}


/**
 * 获取用户列表配置
 * @returns 
 */
 export function getWxUserList(data: any) {
    return service({
        url: `/wx/user/list`,
        method: 'POST',
        data
    });
}