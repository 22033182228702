import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ProColumns } from '@ant-design/pro-table';
import { Link, useNavigate } from 'react-router-dom';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import { getSystemConfigList } from '@src/api/system';
import ProTable from '@ant-design/pro-table';
import { formatRequest } from '@src/utils/common';

type SystemConfigItem = {
    id: number;
    key: string,
    value: string,
    description: string,
    createdAt: string,
};

const List = () => {
    const navigate = useNavigate();

    const columns: ProColumns<SystemConfigItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => [
                <Link to={`/system/config/details/${record.id}`}>{text}</Link>
            ],
        },
        {
            title: 'key',
            dataIndex: 'key',
            key: 'key',
            search: false,
        },
        {
            title: 'value',
            dataIndex: 'value',
            key: 'value',
            ellipsis: true,
            search: false,
        },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description',
            ellipsis: true,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (_, record) => {
                return <>{dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
    ];

    const addItem = () => {
        navigate('/system/config/new');
    }

    return (
        <CContent 
            breadcrumbs={['系统设置', '配置列表']}
        >
            <ProTable<SystemConfigItem>
                request={(...e) => formatRequest(getSystemConfigList, ...e)}
                columns={columns}
                headerTitle={'配置列表'}
                search={false}
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined />} type="primary" onClick={addItem}>
                        新建
                    </Button>
                ]}
            />
        </CContent>
    );
};

export default List