import { useAppSelector } from '@src/app/hooks';
import { getUserState } from '@src/app/reducers/user';
import { PermissionType } from '@src/router/routers';


type Props = {
    children: JSX.Element
} & PermissionType


const Gurad = ({ children, code, category }: Props) => {
    const userPermission = useAppSelector(getUserState).permission;

    if(code && category && !userPermission[code]?.[category])
        return <></>
    else
        return <>{children}</>;
};

export default Gurad