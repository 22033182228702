import service from "./request";

/**
 * 获取角色列表
 * @param data 
 * @returns 
 */
export function getRoleList(data: any): Promise<any> {
    return service({
        url: `/role/list`,
        method: 'POST',
        data
    });
}

/**
 * 删除角色
 * @param data 
 * @returns 
 */
 export function deleteRole(id: number): Promise<any> {
    return service({
        url: `/role/delete/${id}`,
        method: 'POST',
    });
}

/**
 * 获取单个角色列表
 * @param data 
 * @returns 
 */
export function getRoleByID(id: string): Promise<any> {
    return service({
        url: `/role/detail/${id}`,
        method: 'GET',
    });
}

/**
 * 编辑或修改角色
 * @param data 
 * @returns 
 */
export function editRole(data: any): Promise<any> {
    return service({
        url: `/role/edit`,
        method: 'POST',
        data
    });
}

/**
 * 获取用户组权限
 * @param data 
 * @returns 
 */
 export function getRolePermissionList(id: any): Promise<any> {
    return service({
        url: `/role/permission/${id}`,
        method: 'GET',
    });
}


/**
 * 获取用户组权限
 * @param data 
 * @returns 
 */
 export function saveRolePermission(data: { role_id: number, permissions: number[] }): Promise<any> {
    return service({
        url: `/role/permission`,
        method: 'POST',
        data,
    });
}

/**
 * 获取全部用户组
 * @returns 
 */
export function getRoleAll(): Promise<any> {
    return service({
        url: `/role/all`,
        method: 'GET',
    });
}