import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Tag } from 'antd';
import type { ProColumns } from '@ant-design/pro-table';
import { getRoleAll } from '@src/api/role';
import BaseTable from '@src/components/BaseTable.tsx'
import { Link, useNavigate } from 'react-router-dom';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import { getUserList } from '@src/api/user';
import { useEffect, useState } from 'react';
import { RoleItem } from '../../role/list';

type ColumnsItem = {
    id: number;
    name: string;
    loginName: string;
    mail: string;
    roles: {
        role_id: number
    }[]
};


const List = () => {
    const navigate = useNavigate();
    const [roleList, setRoleList] = useState<RoleItem[]>([])
    const [valueEnum, setValueEnum] = useState<any>({})

    useEffect(() => {
        getRoleAll().then((res: RoleItem[]) => {
            setRoleList(res)
            const _valueEnum = res.reduce((obj, current) => {
                obj[current.id] = current.name;
                return obj;
            }, {} as { [x: string]: string });
            setValueEnum(_valueEnum);
        })
    }, [])

    const columns: ProColumns<ColumnsItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '账号',
            dataIndex: 'loginName',
            render: (text, record, _, action) => [
                <Link to={`/system/user/details/${record.id}`}>{text}</Link>
            ],
        },
        {
            title: '用户名',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '邮箱',
            dataIndex: 'mail',
            key: 'mail',
            search: false,
        },
        {
            title: '用户组',
            dataIndex: 'roles',
            key: 'roles',
            valueType: 'select',
            valueEnum: valueEnum,
            search: false,
            width: 300,
            render: (_, record) => (
                <Space size={'small'} wrap={true}>
                    {record.roles.map(({ role_id }) => (
                        <Tag color="processing" key={role_id}>
                            {roleList.find(i => i.id === role_id)?.name}
                        </Tag>
                    ))}
                </Space>
              ),
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            width: 200,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
    ];

    const addItem = () => {
        navigate('/system/user/new');
    }

    return (
        <CContent 
            breadcrumbs={['系统设置', '用户列表']}
        >
            <BaseTable
                request={getUserList}
                columns={columns}
                headerTitle={'用户列表'}
                toolBarRender={() => [
                    <Button key="button" icon={<PlusOutlined />} type="primary" onClick={addItem}>
                        新建
                    </Button>,
                ]}
            />
        </CContent>
    );
};

export default List