import service from "./request";

/**
 * 获取父级菜单
 * @returns 
 */
export function getMenuParent() {
    return service({
        url: `/menu/parent`,
        method: 'GET',
    });
}

/**
 * 编辑或新建
 * @param data 
 * @returns 
 */
export function editMenu(data: any) {
    return service({
        url: `/menu/edit`,
        method: 'POST',
        data
    });
}


/**
 * 获取父级菜单tree
 * @returns 
 */
 export function getAllMenu() {
    return service({
        url: `/menu/list/tree`,
        method: 'GET',
    });
}

/**
 * 获取菜单
 * @returns 
 */
 export function getMenuList(data: any) {
    return service({
        url: `/menu/list`,
        method: 'POST',
        data
    });
}

/**
 * 获取父级菜单
 * @returns 
 */
 export function getMenuPermissionList() {
    return service({
        url: `/menu/permission/list`,
        method: 'GET',
    });
}

/**
 * 获取菜单详情
 * @returns 
 */
 export function getMenuDetail(id: number) {
    return service({
        url: `/menu/detail/${id}`,
        method: 'GET',
    });
}

/**
 * 菜单排序
 * @returns 
 */
 export function setMenuSort(data: any) {
    return service({
        url: `/menu/sort`,
        method: 'POST',
        data
    });
}

/**
 * 用户编辑或新增
 * @param data 
 * @returns 
 */
 export function deleteMenu(id: any): Promise<any> {
    return service({
        url: `/menu/delete/${id}`,
        method: 'POST',
    });
}
