import { getUserInfo } from "@src/api/user";
import { useAppDispatch, useAppSelector } from "@src/app/hooks";
import { clearUser, getUserState, setUser } from "@src/app/reducers/user";
import { Avatar, Button, Layout, message, Popover } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, ReloadOutlined, LogoutOutlined, LockFilled } from '@ant-design/icons'
import '../index.less'
import { Link } from "react-router-dom";

const { Header } = Layout;

type Props = {
    collapsed: boolean,
    setCollapsed: (collapsed: boolean) => void
}

const CHeader = ({collapsed, setCollapsed}: Props) => {
    const user = useAppSelector(getUserState);
    const dispatch = useAppDispatch();

    const signOut = () => {
        dispatch(clearUser());
    }

    const reloadUserInfo = () => {
        const key = 'key';
        message.loading({ content: '更新中...', key });
        getUserInfo().then(res => {
            dispatch(setUser(res));
            message.success({ content: '更新成功!', key, duration: 2 });
        })
    }

    return (
        <Header className="header">
            <div className='left'>
                <div className={['logo', collapsed ? 'collapsed' : ''].join(' ')}>

                </div>
                <div
                    className='collapsed-icon'
                    onClick={() => setCollapsed(!collapsed)}
                >
                    {
                        collapsed
                        ?
                        <MenuUnfoldOutlined />
                        :
                        <MenuFoldOutlined />
                    }
                </div>
            </div>
            <div className='right'>
                <Popover content={<>
                    <p className='popver'>
                        <Link to='/user/changepassword'>
                            <Button type="link" icon={<LockFilled />}>
                                修改密码
                            </Button>
                        </Link>
                    </p>
                    <p className='popver'>
                        <Button type="link" icon={<ReloadOutlined />} onClick={reloadUserInfo}>
                            更新信息
                        </Button>
                    </p>
                    <p className='popver'>
                        <Button type="link" icon={<LogoutOutlined />} onClick={signOut}>
                            登出
                        </Button>
                    </p>
                </>}
                    title={user.info.loginName}
                    trigger="click"
                    placement='bottomRight'>
                    <Avatar size={40}>
                        { user.info.name || user.info.loginName }
                    </Avatar>
                </Popover>
            </div>
        </Header>
    )
}

export default CHeader
