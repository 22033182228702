import service from "./request";

/**
 * 用户登陆
 * @param data 
 * @returns 
 */
export function userLogin(data: { loginName: string, password: string }): Promise<any> {
    return service({
        url: `/user/login`,
        method: 'POST',
        data
    });
}

/**
 * 获取用户列表
 * @param data 
 * @returns 
 */
 export function getUserList(data: any): Promise<any> {
    return service({
        url: `/user/list`,
        method: 'POST',
        data
    });
}


/**
 * 用户编辑或新增
 * @param data 
 * @returns 
 */
 export function editUser(data: any): Promise<any> {
    return service({
        url: `/user/edit`,
        method: 'POST',
        data
    });
}


/**
 * 用户查询
 * @param data 
 * @returns 
 */
 export function getUserByID(id: any): Promise<any> {
    return service({
        url: `/user/detail/${id}`,
        method: 'GET',
    });
}

/**
 * 用户删除
 * @param data 
 * @returns 
 */
export function deleteUser(id: any): Promise<any> {
    return service({
        url: `/user/delete/${id}`,
        method: 'POST',
    });
}


/**
 * 用户编辑或新增
 * @param data 
 * @returns 
 */
 export function pwReset(id: any): Promise<any> {
    return service({
        url: `/user/pw/reset`,
        method: 'POST',
        data: { id }
    });
}

/**
 * 获取用户信息
 * @returns 
 */
export function getUserInfo(): Promise<any> {
    return service({
        url: `/user/reload`,
        method: 'GET',
    });
}


/**
 * 获取用户信息
 * @returns 
 */
 export function changePassword(data: any): Promise<any> {
    return service({
        url: `/user/changePassword`,
        method: 'POST',
        data
    });
}