import { changePassword } from '@src/api/user';
import { RoutedProps, withRouter } from '@src/components/withRouter';
import CContent from '@src/layout/content';
import { Form, Input, Button, Space, Modal, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import md5 from 'md5';

type Props = {
} & RoutedProps


const RoleEdit: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const formRef = useRef<any>(null);


    useEffect(() => {

    }, [props.params])


    const onFinish = (values: any) => {
        if(values.new2 !== values.new) {
            message.error('两次密码输入不一致')
            return;
        }

        setLoading(true);
        const params = {
            old: md5(values.old),
            new: md5(values.new),
        }
        changePassword(params).then(() => {
            Modal.success({ 
                title: '密码重置成功',
                onOk: () => {
                    props.navigate(-1)
                }
            });
        }).finally(() => {
            setLoading(false)
        });
    };


    return (
        <CContent 
            breadcrumbs={['修改密码']}
        >
            <Form
                ref={formRef}
                name="basic"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 8 }}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="原密码"
                    name="old"
                    rules={[{ required: true, message: '请输入密码' }]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="新密码"
                    name="new"
                    rules={[
                        { required: true, message: '请输入密码' },
                        { pattern: /^(?![^a-zA-Z]+$)(?!\D+$)[a-zA-Z0-9]{6,16}$/, message: '密码长度必须7位以上且包含字母数字' }
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="确认密码"
                    name="new2"
                    rules={[
                        { required: true, message: '请输入密码' },
                        { pattern: /^(?![^a-zA-Z]+$)(?!\D+$)[a-zA-Z0-9]{6,16}$/, message: '密码长度必须7位以上且包含字母数字' }
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 3, span: 16 }}>
                    <Space>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            提交
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </CContent>

    )
}

export default withRouter(RoleEdit)