import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import './index.less'
import CMenu from './sider';
import { useState } from 'react';
import CHeader from './header';

const { Sider } = Layout;


const Index = () => {
    const [collapsed, setCollapsed] = useState(false)

    return (
        <Layout className='app_layout'>
            <CHeader collapsed={collapsed} setCollapsed={e => setCollapsed(e)} />
            <Layout>
                <Sider
                    width={200}
                    className="site-layout-background"
                    breakpoint="lg"
                    collapsedWidth="50"
                    collapsed={collapsed}
                >
                    <CMenu collapsed={collapsed} />
                </Sider>
                <Layout style={{ padding: '0 24px 24px' }}>
                    <Outlet />
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Index