import service from "./request";


export function getWallpaperList(data: any) {
    return service({
        url: `/wallpaper/list`,
        method: 'POST',
        data
    });
}

export function refreshWallpaper(data: any) {
    return service({
        url: `/wallpaper/refresh`,
        method: 'POST',
        data
    });
}
