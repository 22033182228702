import { Button, Result } from "antd"
import { useNavigate } from "react-router-dom";
import CContent from "../content";

type Props = {
    type: '404' | '403' | '500'
}

const ErrorPage = ({ type = '404' }: Props) => {
    const navigate = useNavigate();
    const goHome = () => {
        navigate('/');
    }

    return (
        <CContent 
            breadcrumbs={['访问错误']}
        >
            {
                type === '404'
                ?
                <Result
                    status="404"
                    title="404"
                    subTitle="抱歉，您访问的页面不存在。"
                    extra={<Button type="primary" onClick={goHome}>回到首页</Button>}
                />
                :
                (
                    type === '403'
                    ?
                    <Result
                        status="403"
                        title="403"
                        subTitle="抱歉，您无权访问此页面。"
                        extra={<Button type="primary" onClick={goHome}>回到首页</Button>}
                    />
                    :
                    <Result
                        status="500"
                        title="500"
                        subTitle="抱歉，出了一些问题。"
                        extra={<Button type="primary" onClick={goHome}>回到首页</Button>}
                    />
                )
            }
        </CContent>
    )
}

export default ErrorPage