import { PlusOutlined, OrderedListOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import type { ProColumns } from '@ant-design/pro-table';
import BaseTable from '@src/components/BaseTable.tsx'
import { Link, useNavigate } from 'react-router-dom';
import CContent from '@src/layout/content';
import dayjs from 'dayjs';
import { getMenuList } from '@src/api/menu';
import { MenuItem } from '@src/type';



const List = () => {
    const navigate = useNavigate();

    const columns: ProColumns<MenuItem>[] = [
        {
            title: '序号',
            dataIndex: 'index',
            valueType: 'indexBorder',
            width: 48,
        },
        {
            title: '菜单名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => [
                <Link to={`/system/menu/details/${record.id}`}>{text}</Link>
            ],
        },
        {
            title: '跳转地址',
            dataIndex: 'url',
            key: 'url',
            search: false,
        },
        {
            title: '父级菜单',
            dataIndex: 'id',
            key: 'id',
            search: false,
            render: (_, record) => (
                    <>{record.parent?.name || '-'}</>
                )
            ,
        },
        {
            title: '权限',
            dataIndex: 'permission',
            key: 'permission',
            search: false,
            render: (_, record) => (
                    <>{record.permission?.name || '-'}</>
                )
            ,
        },
        {
            title: '图标',
            dataIndex: 'icon',
            key: 'icon',
            search: false,
        },
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
            search: false,
        },
        {
            title: '创建时间',
            dataIndex: 'createdAt',
            key: 'createdAt',
            search: false,
            render: (text) => {
                return <>{dayjs(text as string).format('YYYY-MM-DD HH:mm:ss')}</>
            }
        },
    ];

    const addItem = () => {
        navigate('/system/menu/new');
    }

    return (
        <CContent 
            breadcrumbs={['系统设置', '菜单列表']}
        >
            <BaseTable
                request={getMenuList}
                columns={columns}
                headerTitle={'角色列表'}
                toolBarRender={() => [
                    <Button key="button" icon={<OrderedListOutlined />} type="primary" onClick={() => navigate('/system/menu/sort')}>
                        排序
                    </Button>,
                    <Button key="button" icon={<PlusOutlined />} type="primary" onClick={addItem}>
                        新建
                    </Button>
                ]}
            />
        </CContent>
    );
};

export default List