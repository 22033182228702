import { deletePermission, editPermission, getPermissionByCode } from '@src/api/permission';
import Gurad from '@src/components/Gurad';
import { RoutedProps, withRouter } from '@src/components/withRouter';
import CContent from '@src/layout/content';
import { Form, Input, Button, Space, Modal, Checkbox } from 'antd';
import { useEffect, useRef, useState } from 'react';

type Props = {
} & RoutedProps

const optionsWithDisabled = [
    { label: '查看', value: 'view'},
    { label: '编辑', value: 'edit' },
    { label: '创建', value: 'create'},
    { label: '删除', value: 'delete' },
];

const RoleEdit: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState(false);
    const [model, setModel] = useState<any>({});
    const formRef = useRef<any>(null);


    useEffect(() => {
        if(props.params.id) getPermissionByCode(props.params.id).then(res => {
            setModel(res[0]);
            formRef.current.setFieldsValue({
                ...res[0],
                category: ['view','edit','create','delete']
            });
        })
        else formRef.current.setFieldsValue({
            category: ['view','edit','create','delete']
        });
    }, [props.params])


    const onFinish = (values: any) => {
        setLoading(true)
        editPermission({...model, ...values}).then(() => {
            Modal.success({ 
                title: '编辑成功',
                onOk: () => {
                    props.navigate(-1)
                }
            });
        }).finally(() => {
            setLoading(false)
        });
    };

    const onDelete = () =>  {
        Modal.confirm({
            title: '确认要删除此项吗？',
            okText: '确认',
            cancelText: '取消',
            maskClosable: true,
            onOk: () => {
                deletePermission(model.code).then(_ => {
                    Modal.success({ 
                        title: '删除成功',
                        onOk: () => {
                            props.navigate(-1)
                        }
                    });
                })
            },
            onCancel: () => {}
        })
    }

    return (
        <CContent 
            breadcrumbs={['系统设置', '权限编辑']}
            style={{paddingTop: '24px'}}
        >
            <Form
                ref={formRef}
                name="basic"
                labelCol={{ span: 3 }}
                wrapperCol={{ span: 8 }}
                initialValues={{...model}}
                onFinish={onFinish}
                autoComplete="off"
            >
                <Form.Item
                    label="Code"
                    name="code"
                    rules={[{ required: true, message: '请输入code' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="名称"
                    name="name"
                    rules={[{ required: true, message: '请输入名称' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="描述"
                    name="description"
                    rules={[{ required: false, message: '请输入描述' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="权限类型"
                    name="category"
                >
                    <Checkbox.Group
                        options={optionsWithDisabled}
                            disabled
                            defaultValue={['view','edit','create','delete']}
                        />
                </Form.Item>



                <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                    <Space>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            提交
                        </Button>
                        {
                            model.id ?
                            <Gurad code='permission' category='delete'>
                                <Button type="primary" danger loading={loading} onClick={onDelete}>
                                    删除
                                </Button>
                            </Gurad>
                            :
                            <></>
                        }
                    </Space>
                </Form.Item>
            </Form>
        </CContent>

    )
}

export default withRouter(RoleEdit)