import CContent from '@src/layout/content';
import React from 'react';

const Dashboard = () => {
    return (
        <CContent 
            breadcrumbs={['首页']}
        >
            <div>
                dashboard
                <a href='/login'>login</a>
                <p>
                    <a href="/system/role/list">system/role/list</a>
                </p>
                <p>
                    <a href="/system/permission/list">system/permission/list</a>
                </p>
                <p>
                    <a href="/system/user/list">system/user/list</a>
                </p>
                <p>
                    <a href="/system/menu/list">system/menu/list</a>
                </p>
                <p>
                    <a href="/system/menu">system/menu</a>
                </p>
            </div>
        </CContent>
    )
}

export default Dashboard